import 'foundation-sites';
import 'foundation-datepicker';
import 'foundation-datepicker/js/locales/foundation-datepicker.ja';
import Rails from '@rails/ujs';

$(() => {
  $(document).foundation();
  Rails.start();
  document.dispatchEvent(new CustomEvent('rails-ujs-started'));
  $('.notice-message').each(function () {
    if ($(this).hasClass('warning')) {
      Toastr.warning($(this).html().trim());
    } else if ($(this).hasClass('error') || $(this).hasClass('alert')) {
      Toastr.error($(this).html().trim());
    } else {
      Toastr.success($(this).html().trim());
    }
  });

  const errors = $('#error_explanation li').map(function () { return $(this).html().trim(); });
  if (errors.length > 0) {
    Toastr.error(errors.get().join('<br />'));
  }

  // メニューのis-active判定
  // li.list_itemのdata-active属性の値がbodyタグのclassに含まれていれば、is-active
  $('li.list_item').each(function () {
    if ($('body').hasClass($(this).data('active'))) {
      $(this).addClass('is-active');
    }
  });

  const currentPathName = window.location.pathname.split('/').slice(3, 4).join('/');
  $('.vertical_item').each(function () {
    const item = $(this);
    item.find('a').each(function () {
      const path = new URL(this.href).pathname.split('/').slice(3, 4).join('/');
      if (path.length > 0 && path === currentPathName) {
        item.find('>input').attr('checked', true);
      }
    });
  });
  $('.analyses_vertical_item').each(function () {
    const item = $(this);
    item.find('a').each(function () {
      const path = new URL(this.href).pathname.split('/').slice(3, 5).join('/');
      if (path.length > 0 && path === window.location.pathname.split('/').slice(3, 5).join('/')) {
        item.find('>input').attr('checked', true);
      }
    });
  });

  $('.input_datepicker').fdatepicker({
    format:     'yyyy/mm/dd',
    language:   'ja',
    leftArrow:  '<',
    rightArrow: '︎>',
  });

  // image preview settings
  const elements = document.querySelectorAll('.available-preview');
  Array.prototype.forEach.call(elements, (el, _i) => {
    el.addEventListener('change', function (event) {
      const file = event.target.files[0];

      // Only process image file.
      if (!file || !file.type.match('image.*')) {
        return;
      }

      const reader = new FileReader();
      const previewElement = this.getAttribute('data-preview-element');
      reader.onload = (function (_theFile) {
        return function (e) {
          const content = `<img src="${e.target.result}" />`;
          const target = document.getElementById(previewElement);
          target.innerHTML = content;
          if (target.parentElement.classList.contains('no-image')) {
            target.parentElement.classList.remove('no-image');
          }
        };
      }(file));

      // Read in the image file as a data URL.
      reader.readAsDataURL(file);
    });
  });

  $('.hide-before-load').removeClass('hide-before-load');

  const SwapZIndex = (currentObject, reminderElements) => {
    Array.prototype.forEach.call(reminderElements, (el, _i) => {
      el.style.zIndex = 1;
    });
    currentObject.style.zIndex = 2;
  };

  $('.sidemenu-reminder').on('click', (e) => {
    const reminderElements = document.querySelectorAll('.sidemenu-review_campaign_reminder');
    SwapZIndex(e.currentTarget, reminderElements);
  });

  $('.sidemenu-review_campaign_reminder').on('click', (e) => {
    const reminderElements = document.querySelectorAll('.sidemenu-reminder');
    SwapZIndex(e.currentTarget, reminderElements);
  });

  // 製品紹介文が登録されていない時のリマインダー非表示
  $('.close-reminder').on('click', (e) => {
    e.preventDefault();
    $('.sidemenu-reminder').hide();
  });

  // 開始7日以内で準備中のレビューキャンペーンがある時のリマインダー非表示
  $('.close-eview-campaign-reminder').on('click', (e) => {
    e.preventDefault();
    e.currentTarget.parentElement.hidden = true;
    const productId = window.location.pathname.split('/')[2];
    const url = `/client/${productId}/review_campaigns/review_campaign_close_remander`;
    // セッション情報を設定する
    $.ajax({
      url,
      dataType: 'json',
    });
  });

  // 受賞済みバッジで取得可能なバッジを選択するラジオボタンをクリックした時に説明文が表示される
  const badgeExplanations = {
    normal:        '総合部門で受賞した結果を表示しています',
    large_company: '大企業（従業員数1,000人以上の企業）に評価された称号を表示しています',
    mid_company:   '中堅企業（従業員数100~999人の企業）に評価された称号を表示しています',
    small_company: '中小企業（従業員数100人未満の企業）に評価された称号を表示しています',
    year:          '3年間同じ種別のバッジを連続で受賞した称号を表示しています',
  };
  const radioBtn = document.querySelectorAll("input[type='radio'][name='company-size']");
  for (let i = 0; i < radioBtn.length; i++) {
    radioBtn[i].addEventListener('change', () => {
      if (radioBtn[i].checked && radioBtn[i].value === 'three_year,five_year') {
        document.querySelector('#badge-explanation').innerHTML = badgeExplanations.year;
      } else if (radioBtn[i].checked) {
        document.querySelector('#badge-explanation').innerHTML = badgeExplanations[radioBtn[i].value];
      }
    });
  }
});
